import Vue from "vue";
import Vuex from "vuex";
import authentication from "@/store/modules/authentication";
import currentUser from "@/store/modules/current-user";
import loading from "@/store/modules/loading";
import organization from "@/store/modules/organization";
import floor from "@/store/modules/floor";
import room from "@/store/modules/room";
import roomCategory from "@/store/modules/room-category";
import client from "@/store/modules/client";
import ingredient from "@/store/modules/ingredient";
import kitchenMenu from "@/store/modules/kitchen-menu";
import dish from "@/store/modules/dish";
import service from "@/store/modules/service";
import user from "@/store/modules/user";
import userSheet from "@/store/modules/user-sheet";
import roomTariff from "@/store/modules/room-tariff";
import roomPrice from "@/store/modules/finance";
import paymentType from "@/store/modules/payment-type";
import systemConfig from "@/store/modules/system-config";
import cashBox from '@/store/modules/cash-box';
import exchangeRateGroup from '@/store/modules/exchange-rate-group';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    authentication,
    currentUser,
    loading,
    organization,
    floor,
    room,
    roomCategory,
    client,
    dish,
    kitchenMenu,
    ingredient,
    service,
    user,
    userSheet,
    roomTariff,
    roomPrice,
    paymentType,
    exchangeRateGroup,
    cashBox,
    systemConfig
  }
});
