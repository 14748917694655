import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  setToLocalStorage,
} from "@/utils/local-storage.util";
import { LOGIN_MUTATION } from "@/graphql/mutations/login.mutation";
import { handleGqlResponseBody } from "@/utils/apollo.util";
import { LOGOUT_MUTATION } from "@/graphql/mutations/logout.mutation";
import { RESET_USER_PASSWORD_MUTATION } from "@/graphql/mutations/reset-user-password.mutation";
import { CHECK_RESET_USER_PASSWORD_TOKEN_MUTATION } from "@/graphql/mutations/check-reset-user-password-token.mutation";
import { CONFIRM_RESET_USER_PASSWORD_MUTATION } from "@/graphql/mutations/confirm-reset-user-password.mutation";

export const clearLoginData = () => {
  deleteFromLocalStorage("accessToken");
  deleteFromLocalStorage("refreshToken");
  deleteFromLocalStorage("user");
};

const authentication = {
  state: {
    accessToken: getFromLocalStorage("accessToken"),
    refreshToken: getFromLocalStorage("refreshToken"),
  },
  getters: {
    getAccessToken(state) {
      return state.accessToken;
    },
  },
  mutations: {
    setAccessAndRefreshTokens(state, { accessToken, refreshToken }) {
      state.accessToken = setToLocalStorage("accessToken", accessToken);
      state.refreshToken = setToLocalStorage("refreshToken", refreshToken);
    },
    logOut() {
      this.commit("clearCurrentUser");
      clearLoginData();
    },
  },
  actions: {
    async changeUser({ commit }, loginResponse) {
      commit("setCurrentUser", loginResponse.user);
      commit("setAccessAndRefreshTokens", {
        accessToken: loginResponse.accessToken,
        refreshToken: loginResponse.refreshToken,
      });
    },
    async loginUser({ dispatch }, credentials) {
      const loginResponse = await handleGqlResponseBody(({ mutation }) => {
        return mutation({
          mutation: LOGIN_MUTATION,
          variables: credentials,
        });
      });
      
      dispatch("changeUser", loginResponse);
    },
    async logoutUser({ commit, state }) {
      await handleGqlResponseBody(({ mutation }) => {
        return mutation({
          mutation: LOGOUT_MUTATION,
          variables: {
            refreshToken: state.refreshToken ?? "",
          },
        });
      });

      commit("logOut");
    },
    async resetUserPassword({ commit, state }, { email }) {
      return await handleGqlResponseBody(({ mutation }) => {
        return mutation({
          mutation: RESET_USER_PASSWORD_MUTATION,
          variables: { email },
        });
      });
    },
    async checkResetUserPasswordToken({ commit, state }, { token, code }) {
      return await handleGqlResponseBody(({ mutation }) => {
        return mutation({
          mutation: CHECK_RESET_USER_PASSWORD_TOKEN_MUTATION,
          variables: { token, code },
        });
      });
    },
    async confirmResetUserPassword(
      { commit, state },
      { token, code, password }
    ) {
      return await handleGqlResponseBody(({ mutation }) => {
        return mutation({
          mutation: CONFIRM_RESET_USER_PASSWORD_MUTATION,
          variables: { token, code, password },
        });
      });
    },
  },
};

export default authentication;
