import {handleGqlResponseBody} from '@/utils/apollo.util'
import {GET_CASH_BOX_SHIFT_DATA_PAGINATION_QUERY} from '@/graphql/queries/cash-box/get-cash-box-shift-data-pagination.query';
import {GET_CASH_BOX_DEBT_PAGINATION_QUERY} from '@/graphql/queries/cash-box/get-cash-box-debt-pagination.query';
import {
	GET_PAYMENT_TYPE_CATEGORIES_PAGINATION_QUERY
} from '@/graphql/queries/configuration/payment-type-category/get-payment-type-categories-pagination.query';
import {GET_CASH_BOX_SOURCE_PAGINATION_QUERY} from '@/graphql/queries/configuration/cash-box-source/get-cash-box-source-pagination.query';
import {
	GET_CASH_BOX_SOURCE_CATEGORY_PAGINATION_QUERY
} from '@/graphql/queries/configuration/cash-box-source-category/get-cash-box-source-category-pagination.query';
import {GET_CASH_BOX_PAGINATED_QUERY} from '@/graphql/queries/configuration/cash-box/get-cash-box-pagination.query';
import {GET_MAIN_CASH_BOX_SHIFT_DATA_PAGINATION_QUERY} from '@/graphql/queries/cash-box/get-main-cash-box-shift-data.query';

const cashBox = {
	state: {
		reportIncomeSources: [],
		reportExpenseSources: []
	},
	getters: {
		getReportIncomeSources(state) {
			return state.reportIncomeSources;
		},
		getReportExpenseSources(state) {
			return state.reportExpenseSources;
		}
	},
	mutations: {
		setReportIncomeSources(state, sources) {
			state.reportIncomeSources = sources;
		},
		setReportExpenseSources(state, sources) {
			state.reportExpenseSources = sources;
		},
	},
	actions: {
		async getCashBoxShiftDataPaginatedQuery({commit}, {
			skip, take, orderBy, from, to, onlyOpened
		}) {
			return handleGqlResponseBody(apollo => {
				return apollo.query({
					query: GET_CASH_BOX_SHIFT_DATA_PAGINATION_QUERY,
					variables: {
						skip, take, orderBy, from, to, onlyOpened
					},
					fetchPolicy: 'no-cache'
				})
			})
		},
		async getMainCashBoxDataPaginatedQuery({commit}, {
			skip, take, orderBy
		}) {
			return handleGqlResponseBody(apollo => {
				return apollo.query({
					query: GET_MAIN_CASH_BOX_SHIFT_DATA_PAGINATION_QUERY,
					variables: {
						skip, take, orderBy
					},
					fetchPolicy: 'no-cache'
				})
			})
		},
		async getCashBoxDebtPaginatedQuery({commit}, {
			skip, take, orderBy, search, type
		}) {
			console.log('FHDSKJFHDSKJHFDSK JHF KJSHFJKSH FJKHJSKHF JKSDJH FJKHKS JHF DJKS', {
				skip, take, orderBy, search, type
			})
			return handleGqlResponseBody(apollo => {
				return apollo.query({
					query: GET_CASH_BOX_DEBT_PAGINATION_QUERY,
					variables: {
						skip, take, orderBy, search, type
					},
					fetchPolicy: 'no-cache'
				})
			})
		},
		async getCashBoxPaginatedQuery({ commit }, { skip, take, orderBy }) {
			return await handleGqlResponseBody(({ query }) => {
				return query({
					query: GET_CASH_BOX_PAGINATED_QUERY,
					variables: {
						skip,
						take,
						orderBy,
					},
					fetchPolicy: "no-cache",
				});
			});
		},
		async getCashBoxSourcePaginatedQuery({ commit }, { skip, take, orderBy, search }) {
			return await handleGqlResponseBody(({ query }) => {
				return query({
					query: GET_CASH_BOX_SOURCE_PAGINATION_QUERY,
					variables: {
						skip,
						take,
						orderBy,
						query: search,
					},
					fetchPolicy: "no-cache",
				});
			});
		},
		async getCashBoxSourceCategoryPaginatedQuery({ commit }, { skip, take, orderBy, search }) {
			return await handleGqlResponseBody(({ query }) => {
				return query({
					query: GET_CASH_BOX_SOURCE_CATEGORY_PAGINATION_QUERY,
					variables: {
						skip,
						take,
						orderBy,
						query: search,
					},
					fetchPolicy: "no-cache",
				});
			});
		},
	}
}

export default cashBox
