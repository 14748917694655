<template>
			<v-img src="@/assets/images/logo.jpeg" width="120" height="60" class="logo"/>
</template>

<script>
export default {
  name: 'LogoComponent',
  props: {
    hasShadow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.logo {
  margin: 0 auto;
}
</style>
