<template>
  <v-select
    class="d-inline-block"
    style="width: 80px"
    v-model="currentLanguage"
    :items="languages"
    item-text="title"
    item-value="id"
    outlined
    dense
    hide-details="auto"
  />
</template>

<script>
import {
  getObjectFromLocalStorage,
  setObjectToLocalStorage,
} from "@/utils/local-storage.util";

export default {
  computed: {
    currentLanguage: {
      get() {
        return getObjectFromLocalStorage("lang");
      },
      set(code) {
        setObjectToLocalStorage("lang", code);
        this.$i18n.locale = code;
      },
    },
    languages() {
      return Object.keys(this.$i18n.messages).map((id) => ({
        id,
        title: id.toUpperCase(),
      }));
    },
  },
};
</script>

<style scoped>
@media (max-width: 480px) {
  .lang {
    flex-wrap: nowrap;
  }

  .lang .btn {
    min-width: 0;
  }
}
</style>
